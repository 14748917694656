// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { AuthContext } from "../stores/contexts";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = observer(({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  console.log("IN PRIVATE ", children.location);
  auth.setRedirectURL(children);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});

export default PrivateRoute;
