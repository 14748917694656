import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { observer } from "mobx-react-lite";
import { CoachingStoreContext } from "../stores/contexts";
import { useHistory } from "react-router-dom";

const CoachComment = observer((props) => {
  const { id } = props;
  const [comments, setComments] = useState();
  const coachingStore = useContext(CoachingStoreContext);
  let history = useHistory();
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20, width: "100%" }}
    >
      <TextField
        id="outlined-multiline-static"
        label="Message To Student"
        multiline
        rows={5}
        placeholder="Type a message back your student"
        variant="outlined"
        style={{ width: "50%" }}
        value={comments}
        onChange={(e) => {
          setComments(e.target.value);
        }}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 50, width: "80%" }}
        onClick={() => {
          coachingStore.submitCoachResponse(comments);
          alert("Your response has been sent to the student");
          history.push("/");
        }}
      >
        Send to Student
      </Button>
    </Box>
  );
});

export default CoachComment;
