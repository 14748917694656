import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Player } from "video-react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

var _ = require("lodash");

const Plane = observer((props) => {
  const { analysisData, dtl0Data, dtl1Data } = props;
  const [planes, setPlanes] = useState([]);
  const [wrists1, setWrists1] = useState([]);
  const [wrists2, setWrists2] = useState([]);

  useEffect(() => {
    let recs = [];
    for (let i in analysisData) {
      let analysis = analysisData[i];
      if (
        analysis.type === "dtl" &&
        analysis.presetName === "Swing Plane (Only Down The Line)"
      ) {
        let ind = _.findIndex(recs, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1) {
          recs.push(analysis);
        }
      }
    }
    setPlanes(recs);
  }, [analysisData]);

  useEffect(() => {
    if (dtl0Data) {
      let recs = [];
      for (let i in dtl0Data.data) {
        let dat = dtl0Data.data[i];
        if (dat.count > 90) {
          break;
        }
        let obj = {
          count: dat.count,
          l_x: dat.l_wrist_x,
          l_y: -dat.l_wrist_y,
          r_x: dat.r_wrist_x,
          r_y: -dat.r_wrist_y,
        };
        recs.push(obj);
      }
      setWrists1(recs);
    }
  }, [dtl0Data]);

  useEffect(() => {
    if (dtl1Data) {
      let recs = [];
      for (let i in dtl1Data.data) {
        let dat = dtl1Data.data[i];
        if (dat.count > 90) {
          break;
        }
        let obj = {
          count: dat.count,
          l_x: dat.l_wrist_x,
          l_y: -dat.l_wrist_y,
          r_x: dat.r_wrist_x,
          r_y: -dat.r_wrist_y,
        };
        recs.push(obj);
      }
      setWrists2(recs);
    }
  }, [dtl1Data]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 0 }}
    >
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Detailed Breakdown</Typography>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Swing Plane</Typography>

      {planes && (
        <Grid container justify="center" spacing={10}>
          {planes.map((data, index) => (
            <Grid item key={index}>
              {data.completed && (
                <Player
                  playsInline
                  src={data.video_url}
                  fluid={false}
                  height={600}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Swing 1</Typography>

      <Grid container justify="center" spacing={10}>
        <Grid item>
          <ScatterChart
            width={400}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="l_x"
              name="X"
              domain={[
                Math.min.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.l_wrist_x;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.l_wrist_x;
                  })
                ) + 100,
              ]}
            />
            <YAxis
              type="number"
              dataKey="l_y"
              name="Y"
              domain={[
                Math.min.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.l_wrist_y;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.l_wrist_y;
                  })
                ) + 100,
              ]}
            />
            <ZAxis
              type="number"
              dataKey="count"
              range={[
                Math.min.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.count;
                  })
                ),
                Math.max.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.count;
                  })
                ),
              ]}
            />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            <Scatter name="L Wrist" data={wrists1} fill="#8884d8" />
          </ScatterChart>
        </Grid>

        <Grid item>
          <ScatterChart
            width={400}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="r_x"
              name="X"
              domain={[
                Math.min.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.r_wrist_x;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.r_wrist_x;
                  })
                ) + 100,
              ]}
            />
            <YAxis
              type="number"
              dataKey="r_y"
              name="Y"
              domain={[
                Math.min.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.r_wrist_y;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.r_wrist_y;
                  })
                ) + 100,
              ]}
            />
            <ZAxis
              type="number"
              dataKey="count"
              range={[
                Math.min.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.count;
                  })
                ),
                Math.max.apply(
                  null,
                  wrists1.map(function (item) {
                    return item.count;
                  })
                ),
              ]}
            />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            <Scatter name="R Wrist" data={wrists1} fill="#8884d8" />
          </ScatterChart>
        </Grid>
      </Grid>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Swing 2</Typography>
      <Grid container justify="center" spacing={10}>
        <Grid item>
          <ScatterChart
            width={400}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="l_x"
              name="X"
              domain={[
                Math.min.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.l_wrist_x;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.l_wrist_x;
                  })
                ) + 100,
              ]}
            />
            <YAxis
              type="number"
              dataKey="l_y"
              name="Y"
              domain={[
                Math.min.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.l_wrist_y;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.l_wrist_y;
                  })
                ) + 100,
              ]}
            />
            <ZAxis
              type="number"
              dataKey="count"
              range={[
                Math.min.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.count;
                  })
                ),
                Math.max.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.count;
                  })
                ),
              ]}
            />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            <Scatter name="L Wrist" data={wrists2} fill="#8884d8" />
          </ScatterChart>
        </Grid>

        <Grid item>
          <ScatterChart
            width={400}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="r_x"
              name="X"
              domain={[
                Math.min.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.r_wrist_x;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.r_wrist_x;
                  })
                ) + 100,
              ]}
            />
            <YAxis
              type="number"
              dataKey="r_y"
              name="Y"
              domain={[
                Math.min.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.r_wrist_y;
                  })
                ) - 100,
                Math.max.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.r_wrist_y;
                  })
                ) + 100,
              ]}
            />
            <ZAxis
              type="number"
              dataKey="count"
              range={[
                Math.min.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.count;
                  })
                ),
                Math.max.apply(
                  null,
                  wrists2.map(function (item) {
                    return item.count;
                  })
                ),
              ]}
            />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            <Scatter name="R Wrist" data={wrists2} fill="#8884d8" />
          </ScatterChart>
        </Grid>
      </Grid>
    </Box>
  );
});

export default Plane;
