import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Coach from "./Coach";
import SetupFaceOnDetailed from "../components/SetupFaceOnDetailed";
import SetupDTLDetailed from "../components/SetupDTLDetailed";
import SpineFaceOnDetailed from "../components/SpineFaceOnDetailed";
import * as firebase from "firebase";
import { useHistory, useParams } from "react-router-dom";
import { AnalysisContext } from "../stores/contexts";
import { observer } from "mobx-react-lite";
import HipMovement from "../components/HipMovement";
import Plane from "../components/Plane";
import Tempo from "../components/Tempo";
import LinearProgress from "@material-ui/core/LinearProgress";
import StudentResponse from "../components/StudentResponse";
import { CoachingStoreContext } from "../stores/contexts";
import Transition from "../components/Transition";

const DiagnosticAnalysis = observer((props) => {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const db = firebase.firestore();
  const storage = firebase.storage();
  let { id } = useParams();
  const [reqData, setReqData] = useState();
  const [analysisData, setAnalysisData] = useState([]);
  const [front0, setFront0] = useState("");
  const [front1, setFront1] = useState("");
  const [dtl0, setDtl0] = useState("");
  const [dtl1, setDtl1] = useState("");
  const [temp0, setTemp0] = useState("");
  const [temp1, setTemp1] = useState("");
  const [front0Data, setFront0Data] = useState();
  const [front1Data, setFront1Data] = useState();
  const [dtl0Data, setDTL0Data] = useState();
  const [dtl1Data, setDTL1Data] = useState();
  const [temp0Data, setTemp0Data] = useState();
  const [temp1Data, setTemp1Data] = useState();
  const [setupFaceOn, setSetupFaceOn] = useState(0);
  const [setupDTL, setSetupDTL] = useState(0);
  const [spineTilt, setSpineTilt] = useState(0);
  const [spineAngle, setSpineAngle] = useState(0);
  const [hip, setHip] = useState(0);
  const [tempo, setTempo] = useState(0);
  const [swingPlane, setSwingPlane] = useState(0);
  const coachingStore = useContext(CoachingStoreContext);

  const analysis = useContext(AnalysisContext);
  let history = useHistory();

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10 * 1000);
  }, []);

  useEffect(() => {
    coachingStore.setCoachingValues("activeCoachingID", id);
  }, [id]);

  useEffect(() => {
    (async () => {
      let reqDoc = await db
        .collection("mvp_coach_analysis_request")
        .doc(id)
        .get();

      if (reqDoc.exists) {
        let data = reqDoc.data();
        setReqData(data);
      }
    })();
  }, [db, id]);

  useEffect(() => {
    if (reqData) {
      let diagnostic = reqData.diagnostic;

      if (
        diagnostic.f0.includes("Good face on setup position") &&
        diagnostic.f1.includes("Good face on setup position")
      ) {
        setSetupFaceOn(2);
      } else if (
        diagnostic.f0.includes("Good face on setup position") ||
        diagnostic.f1.includes("Good face on setup position")
      ) {
        setSetupFaceOn(1);
      }

      if (
        diagnostic.d2.includes("Good down the line setup position") &&
        diagnostic.d3.includes("Good down the line setup position")
      ) {
        setSetupDTL(2);
      } else if (
        diagnostic.d2.includes("Good down the line setup position") ||
        diagnostic.d3.includes("Good down the line setup position")
      ) {
        setSetupDTL(1);
      }

      if (
        diagnostic.f0.includes("Stable Spine Tilt During Backswing") &&
        diagnostic.f0.includes("Stable Spine Tilt During Downswing") &&
        diagnostic.f1.includes("Stable Spine Tilt During Backswing") &&
        diagnostic.f1.includes("Stable Spine Tilt During Downswing")
      ) {
        setSpineTilt(2);
      } else if (
        diagnostic.f0.includes("Stable Spine Tilt During Backswing") ||
        (diagnostic.f0.includes("Stable Spine Tilt During Downswing") &&
          diagnostic.f1.includes("Stable Spine Tilt During Backswing") &&
          diagnostic.f1.includes("Stable Spine Tilt During Downswing"))
      ) {
        setSpineTilt(1);
      }

      if (
        diagnostic.d2.includes("Maintained stable spine") &&
        diagnostic.d2.includes("Maintained stable spine")
      ) {
        setSpineAngle(2);
      } else if (
        diagnostic.d2.includes("Stable spine during back swing") ||
        (diagnostic.d2.includes("Stable spine during down swing") &&
          diagnostic.d3.includes("Stable spine during back swing") &&
          diagnostic.d3.includes("Stable spine during down swing"))
      ) {
        setSpineAngle(1);
      }

      if (
        diagnostic.f0.includes("Good Hip Rotation Downswing") &&
        diagnostic.f0.includes("Good Hip Rotation Backswing") &&
        diagnostic.f1.includes("Good Hip Rotation Downswing") &&
        diagnostic.f1.includes("Good Hip Rotation Backswing")
      ) {
        setHip(2);
      } else if (
        diagnostic.f0.includes("Good Hip Rotation Downswing") &&
        diagnostic.f0.includes("Good Hip Rotation Backswing") &&
        diagnostic.f1.includes("Good Hip Rotation Downswing") &&
        diagnostic.f1.includes("Good Hip Rotation Backswing")
      ) {
        setHip(1);
      }

      if (diagnostic.t4 && diagnostic.t5) {
        if (
          diagnostic?.t4.includes("Good swing tempo") &&
          diagnostic?.t5.includes("Good swing tempo")
        ) {
          setTempo(2);
        } else if (
          diagnostic?.t4.includes("Good swing tempo") ||
          diagnostic?.t5.includes("Good swing tempo")
        ) {
          setTempo(1);
        }
      }

      if (
        diagnostic.d2.includes("On plane during downswing") &&
        diagnostic.d3.includes("On plane during downswing")
      ) {
        setSwingPlane(2);
      } else if (
        diagnostic.d2.includes("On plane during downswing") ||
        diagnostic.d3.includes("On plane during downswing")
      ) {
        setSwingPlane(1);
      }
    }
  }, [reqData]);

  useEffect(() => {
    (async () => {
      if (reqData) {
        let analysisSnap = await db
          .collection("analyzed_videos")
          .where(
            "diagnosticSerial",
            "==",
            reqData?.diagnostic?.diagnosticSerial
          )
          .get();

        analysisSnap.forEach(async (doc) => {
          let analysis = doc.data();
          analysis.diagKey = analysis.type[0] + analysis.diagnosticNumber;
          analysis.id = doc.id;

          analysis.video_url = await storage
            .ref()
            .child("analyzed_videos/" + analysis.videoName + ".mp4")
            .getDownloadURL();
          analysis.raw_video_url = await storage
            .ref()
            .child(analysis.original_video_name)
            .getDownloadURL();
          let vidRef = await db
            .collection("mvp_videos")
            .doc(analysis.mvpVideoId)
            .get();
          if (vidRef.exists) {
            let data = vidRef.data();

            analysis.raw_video_url = await storage
              .ref()
              .child(data.mainUrl)
              .getDownloadURL();

            if (analysis.diagnosticNumber === 0 && front0 === "") {
              setFront0(analysis.raw_video_url);
              let processedRef = await db
                .collection("processed_pose_data")
                .doc(vidRef.id)
                .get();
              if (processedRef.exists) {
                let processedData = processedRef.data();
                setFront0Data(processedData);
              }
            } else if (analysis.diagnosticNumber === 1 && front1 === "") {
              setFront1(analysis.raw_video_url);
              let processedRef = await db
                .collection("processed_pose_data")
                .doc(vidRef.id)
                .get();
              if (processedRef.exists) {
                let processedData = processedRef.data();
                setFront1Data(processedData);
              }
            } else if (analysis.diagnosticNumber === 2 && dtl0 === "") {
              setDtl0(analysis.raw_video_url);
              let processedRef = await db
                .collection("processed_pose_data")
                .doc(vidRef.id)
                .get();
              if (processedRef.exists) {
                let processedData = processedRef.data();
                setDTL0Data(processedData);
              }
            } else if (analysis.diagnosticNumber === 3 && dtl1 === "") {
              setDtl1(analysis.raw_video_url);
              let processedRef = await db
                .collection("processed_pose_data")
                .doc(vidRef.id)
                .get();
              if (processedRef.exists) {
                let processedData = processedRef.data();
                setDTL1Data(processedData);
              }
            } else if (analysis.diagnosticNumber === 4 && temp0 === "") {
              setTemp0(analysis.raw_video_url);
              let processedRef = await db
                .collection("processed_pose_data")
                .doc(vidRef.id)
                .get();
              if (processedRef.exists) {
                let processedData = processedRef.data();
                setTemp0Data(processedData);
              }
            } else if (analysis.diagnosticNumber === 5 && temp1 === "") {
              setTemp1(analysis.raw_video_url);
              let processedRef = await db
                .collection("processed_pose_data")
                .doc(vidRef.id)
                .get();
              if (processedRef.exists) {
                let processedData = processedRef.data();
                setTemp1Data(processedData);
              }
            }
          }
          setAnalysisData((prevArray) => [...prevArray, analysis]);
        });
      }
    })();
  }, [reqData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    coachingStore.setCoachingValues("recommendedDrill", newValue);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        style={{ marginTop: 10 }}
      >
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ width: "80%" }}
        />
        <h1>Loading...</h1>
      </Box>
    );
  } else {
    return (
      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
          >
            <Tab label={"Home"} />
            <Tab label="Setup" />
            <Tab label="Spine" />
            <Tab label="Hips" />
            <Tab label="Plane" />
            <Tab label="Tempo" />
            <Tab label={"Response"} />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <Coach
            front0={front0}
            front1={front1}
            dtl0={dtl0}
            dtl1={dtl1}
            reqData={reqData}
            analysisData={analysisData}
            setupFaceOn={setupFaceOn}
            spineTilt={spineTilt}
            hip={hip}
            tempo={tempo}
            setupDTL={setupDTL}
            spineAngle={spineAngle}
            swingPlane={swingPlane}
            setValue={setValue}
          />
        )}
        {value === 1 && (
          <div>
            <SetupFaceOnDetailed
              analysisData={analysisData}
              front0Data={front0Data}
              front1Data={front1Data}
            />
            <SetupDTLDetailed
              analysisData={analysisData}
              dtl0Data={dtl0Data}
              dtl1Data={dtl1Data}
            />
          </div>
        )}
        {value === 2 && (
          <div>
            <SpineFaceOnDetailed
              analysisData={analysisData}
              front0Data={front0Data}
              front1Data={front1Data}
              dtl0Data={dtl0Data}
              dtl1Data={dtl1Data}
            />
          </div>
        )}
        {value === 3 && (
          <div>
            <HipMovement
              analysisData={analysisData}
              front0Data={front0Data}
              front1Data={front1Data}
            />
          </div>
        )}
        {value === 4 && (
          <div>
            <Plane
              analysisData={analysisData}
              dtl0Data={dtl0Data}
              dtl1Data={dtl1Data}
            />
          </div>
        )}
        {value === 5 && (
          <div>
            <Tempo
              analysisData={analysisData}
              temp0Data={temp0Data}
              temp1Data={temp1Data}
            />
          </div>
        )}
        {value === 6 && (
          <div>
            <StudentResponse analysisData={analysisData} id={id} />
          </div>
        )}
      </div>
    );
  }
});

export default DiagnosticAnalysis;
