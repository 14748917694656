import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Player } from "video-react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from "recharts";

var _ = require("lodash");

function getDistance(x1, y1, x2, y2) {
  var xs = x2 - x1;
  var ys = y2 - y1;

  xs *= xs;
  ys *= ys;

  return Math.sqrt(xs + ys);
}

const Transition = observer((props) => {
  const { analysisData, temp0Data, temp1Data } = props;
  const [graphData0, setGraphData0] = useState([]);

  useEffect(() => {
    let data = temp0Data.data;
    let graph0 = [];
    let lHipStartX = 0;
    let lHipStartY = 0;
    let lShoulderStartX = 0;
    let lShoulderStartY = 0;
    let lWristStartX = 0;
    let lWristStartY = 0;
    console.log("top of bs ", data.top_bs);
    for (let i in data) {
      let dat = data[i];

      if (dat.count === -1) {
        lHipStartX = dat.l_hip_x;
        lHipStartY = dat.l_hip_y;
        lShoulderStartX = dat.l_shoulder_x;
        lShoulderStartY = dat.l_shoulder_y;
        lWristStartX = dat.l_wrist_x;
        lWristStartY = dat.l_wrist_y;
      } else if (
        dat.count <= temp0Data.top_bs + 10 &&
        dat.count >= temp0Data.swing_start - 10
      ) {
        let lHipDist = getDistance(
          lHipStartX,
          lHipStartY,
          dat.l_hip_x,
          dat.l_hip_y
        );
        let lShoulderDist = getDistance(
          lShoulderStartX,
          lShoulderStartY,
          dat.l_shoulder_x,
          dat.l_shoulder_y
        );
        let lWristDist = getDistance(
          lWristStartX,
          lWristStartY,
          dat.l_wrist_x,
          dat.l_wrist_y
        );
        let obj = {
          count: dat.count,
          lHipDist: lHipDist,
          lShoulderDist: lShoulderDist,
          lWristDist: lWristDist,
        };
        graph0.push(obj);
      }
    }

    setGraphData0(graph0);
    console.log("graph0Data", temp0Data);
  }, [temp0Data]);
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 0 }}
    >
      <h1>Transition</h1>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={graphData0}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"count"} type="number" />
        <YAxis type="number" />
        <Tooltip />
        <Legend />
        <Line name="L Hip" dataKey={"lHipDist"} fill="blue" />
      </LineChart>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={graphData0}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"count"} type="number" />
        <YAxis type="number" />
        <Tooltip />
        <Legend />

        <Line name="L Shoulder" dataKey={"lShoulderDist"} fill="red" />
      </LineChart>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={graphData0}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"count"} type="number" />
        <YAxis type="number" />
        <Tooltip />
        <Legend />

        <Line name="L Wrist" dataKey={"lWristDist"} fill="green" />
      </LineChart>
    </Box>
  );
});

export default Transition;
