import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CoachingStoreContext } from "../stores/contexts";

const HipTols = observer((props) => {
  const { faceOnTols } = props;
  const [allowableSwayAwayBS, setAllowableSwayAwayBS] = useState();
  const [allowableSwayAwayDS, setAllowableSwayAwayDS] = useState();
  const [allowableSwayTowardBS, setAllowableSwayTowardBS] = useState();
  const [allowableSwayTowardDS, setAllowableSwayTowardDS] = useState();
  const [rotationRate, setRotationRate] = useState();
  const coachingStore = useContext(CoachingStoreContext);

  useEffect(() => {
    if (faceOnTols) {
      setAllowableSwayAwayBS(faceOnTols.allowableSwayAwayBS);
      setAllowableSwayAwayDS(faceOnTols.allowableSwayAwayDS);
      setAllowableSwayTowardBS(faceOnTols.allowableSwayTowardBS);
      setAllowableSwayTowardDS(faceOnTols.allowableSwayTowardDS);
      setRotationRate(faceOnTols.rotationRate);
      coachingStore.setCoachingValues(
        "allowableSwayAwayBS",
        faceOnTols.allowableSwayAwayBS
      );
      coachingStore.setCoachingValues(
        "allowableSwayTowardBS",
        faceOnTols.allowableSwayAwayDS
      );
      coachingStore.setCoachingValues(
        "allowableSwayAwayDS",
        faceOnTols.allowableSwayTowardBS
      );
      coachingStore.setCoachingValues(
        "allowableSwayTowardDS",
        faceOnTols.allowableSwayTowardDS
      );
      coachingStore.setCoachingValues("rotationRate", faceOnTols.rotationRate);
    }
  }, [faceOnTols]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20 }}
    >
      <h1>Hip Movement</h1>
      <Grid container spacing={5}>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Backswing Allowable Sway Away"
              value={allowableSwayAwayBS}
              onChange={(e) => {
                setAllowableSwayAwayBS(e.target.value);
                coachingStore.setCoachingValues(
                  "allowableSwayAwayBS",
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Backswing Allowable Sway Towards"
              value={allowableSwayTowardBS}
              onChange={(e) => {
                setAllowableSwayTowardBS(e.target.value);
                coachingStore.setCoachingValues(
                  "allowableSwayTowardBS",
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={7}>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Downwing Allowable Sway Away"
              value={allowableSwayAwayDS}
              onChange={(e) => {
                setAllowableSwayAwayDS(e.target.value);
                coachingStore.setCoachingValues(
                  "allowableSwayAwayDS",
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Downswing Allowable Towards"
              value={allowableSwayTowardDS}
              onChange={(e) => {
                setAllowableSwayTowardDS(e.target.value);
                coachingStore.setCoachingValues(
                  "allowableSwayTowardDS",
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={7}>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Desired Rotation Rate"
              value={rotationRate}
              onChange={(e) => {
                setRotationRate(e.target.value);
                coachingStore.setCoachingValues("rotationRate", e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default HipTols;
