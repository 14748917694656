import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CoachingStoreContext } from "../stores/contexts";

const SetupDTLCoachTolerances = observer((props) => {
  const { dtlTols } = props;
  const [minArmHang, setMinArmHang] = useState();
  const [maxArmHang, setMaxArmHang] = useState();
  const [minKneeFlex, setMinKneeFlex] = useState();
  const [maxKneeFlex, setMaxKneeFlex] = useState();
  const [minPelvicTilt, setMinPelvicTilt] = useState();
  const [maxPelvicTilt, setMaxPelvicTilt] = useState();
  const [minSpineAngle, setMinSpineAngle] = useState();
  const [maxSpineAngle, setMaxSpineAngle] = useState();
  const coachingStore = useContext(CoachingStoreContext);

  useEffect(() => {
    if (dtlTols) {
      console.log("in dtl tols ", dtlTols);
      setMinArmHang(dtlTols.minArmHang);
      setMaxArmHang(dtlTols.maxArmHang);
      setMinKneeFlex(dtlTols.minKneeFlex);
      setMaxKneeFlex(dtlTols.maxKneeFlex);
      setMinPelvicTilt(dtlTols.minPelvicTilt);
      setMaxPelvicTilt(dtlTols.maxPelvicTilt);
      setMinSpineAngle(dtlTols.minSpineAngle);
      setMaxSpineAngle(dtlTols.maxSpineAngle);
      coachingStore.setCoachingValues("minArmHang", dtlTols.minArmHang);
      coachingStore.setCoachingValues("maxArmHang", dtlTols.maxArmHang);
      coachingStore.setCoachingValues("minKneeFlex", dtlTols.minKneeFlex);
      coachingStore.setCoachingValues("maxKneeFlex", dtlTols.maxKneeFlex);
      coachingStore.setCoachingValues("minPelvicTilt", dtlTols.maxPelvicTilt);
      coachingStore.setCoachingValues("minPelvicTilt", dtlTols.maxPelvicTilt);
      coachingStore.setCoachingValues("minSpineAngle", dtlTols.minSpineAngle);
      coachingStore.setCoachingValues("maxSpineAngle", dtlTols.maxSpineAngle);
    }
  }, [dtlTols]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20 }}
    >
      <h1>Setup DTL</h1>
      <Grid container spacing={5} style={{ marginTop: 20 }}>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Arm Hang Angle"
              value={minArmHang}
              onChange={(e) => {
                setMinArmHang(e.target.value);
                coachingStore.setCoachingValues("minArmHang", e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Arm Hang Angle"
              value={maxArmHang}
              onChange={(e) => {
                setMaxArmHang(e.target.value);
                coachingStore.setCoachingValues("maxArmHang", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Knee Flex Angle"
              value={minKneeFlex}
              onChange={(e) => {
                setMinKneeFlex(e.target.value);
                coachingStore.setCoachingValues("minKneeFlex", e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Knee Flex Angle"
              value={maxKneeFlex}
              onChange={(e) => {
                setMaxKneeFlex(e.target.value);
                coachingStore.setCoachingValues("maxKneeFlex", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Pelvic Tilt Angle"
              value={minPelvicTilt}
              onChange={(e) => {
                setMinPelvicTilt(e.target.value);
                coachingStore.setCoachingValues(
                  "minPelvicTilt",
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Pelvic Tilt Angle"
              value={maxPelvicTilt}
              onChange={(e) => {
                setMaxPelvicTilt(e.target.value);
                coachingStore.setCoachingValues(
                  "maxPelvicTilt",
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Spine Angle"
              value={minSpineAngle}
              onChange={(e) => {
                setMinSpineAngle(e.target.value);
                coachingStore.setCoachingValues(
                  "minSpineAngle",
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Spine Angle"
              value={maxSpineAngle}
              onChange={(e) => {
                setMaxSpineAngle(e.target.value);
                coachingStore.setCoachingValues(
                  "maxSpineAngle",
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default SetupDTLCoachTolerances;
