import Box from "@material-ui/core/Box";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import GaugeChart from "react-gauge-chart";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import { Player } from "video-react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { get } from "mobx";

var _ = require("lodash");

const SetupFaceOnDetailed = observer((props) => {
  const { analysisData, front0Data, front1Data } = props;
  const [setup, setSetup] = useState([]);
  const distance = (x0, y0, x1, y1) => Math.hypot(x1 - x0, y1 - y0);

  function getColor(min, max, val) {
    if (parseInt(min) <= parseInt(val) && parseInt(val) <= parseInt(max)) {
      return { backgroundColor: "green", color: "white" };
    } else {
      return { backgroundColor: "red", color: "white" };
    }
  }

  useEffect(() => {
    let recs = [];
    for (let i in analysisData) {
      let analysis = analysisData[i];
      if (
        analysis.type === "front" &&
        analysis.presetName === "Setup" &&
        analysis.diagnosticNumber !== 4 &&
        analysis.diagnosticNumber !== 5
      ) {
        let ind = _.findIndex(recs, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1) {
          recs.push(analysis);
        }
      }
    }
    console.log("SETUPS ", recs);
    setSetup(recs);
  }, [analysisData]);

  useEffect(() => {
    if (front0Data) {
      const dist0 = distance(
        front0Data.data[0].l_shoulder_x,
        front0Data.data[0].l_shoulder_y,
        front0Data.data[0].l_wrist_x,
        front0Data.data[0].l_wrist_y
      );
      front0Data.setup_stance_width_in = Math.round(
        front0Data.setup_stance_width * (25 / dist0)
      );
    }

    if (front1Data) {
      const dist1 = distance(
        front1Data.data[0].l_shoulder_x,
        front1Data.data[0].l_shoulder_y,
        front1Data.data[0].l_wrist_x,
        front1Data.data[0].l_wrist_y
      );

      front1Data.setup_stance_width_in = Math.round(
        front1Data.setup_stance_width * (25 / dist1)
      );
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 0 }}
    >
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Detailed Breakdown</Typography>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Setup Face On</Typography>

      <Grid container justify="center" spacing={10}>
        {setup.map((data, index) => (
          <Grid item key={index}>
            {data.completed && (
              <Player
                playsInline
                src={data.video_url}
                fluid={false}
                height={600}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Swing</TableCell>
              <TableCell align="center">Spine Tilt Min</TableCell>
              <TableCell align="center">Spine Tilt</TableCell>
              <TableCell align="center">Spine Tilt Max</TableCell>
              <TableCell align="center">Shoulder Tilt Min</TableCell>
              <TableCell align="center">Shoulder Tilt</TableCell>
              <TableCell align="center">Shoulder Tilt Max</TableCell>
              <TableCell align="center">Stance Width (in)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {front0Data && (
              <TableRow>
                <TableCell align="center">Swing 1</TableCell>
                <TableCell align="center">{setup[0]?.minSpineTilt}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[0]?.minSpineTilt,
                    setup[0]?.maxSpineTilt,
                    front0Data.setup_spine_tilt
                  )}
                >
                  {front0Data.setup_spine_tilt}
                </TableCell>
                <TableCell align="center">{setup[0]?.maxSpineTilt}</TableCell>
                <TableCell align="center">
                  {setup[0]?.minShoulderTilt}
                </TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[0]?.minShoulderTilt,
                    setup[0]?.maxShoulderTilt,
                    front0Data.setup_shoulder_tilt
                  )}
                >
                  {front0Data.setup_shoulder_tilt}
                </TableCell>
                <TableCell align="center">
                  {setup[0]?.maxShoulderTilt}
                </TableCell>
                <TableCell align="center">
                  {front0Data.setup_stance_width_in}
                </TableCell>
              </TableRow>
            )}
            {front1Data && (
              <TableRow>
                <TableCell align="center">Swing 2</TableCell>
                <TableCell align="center">{setup[1]?.minSpineTilt}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[1]?.minSpineTilt,
                    setup[1]?.maxSpineTilt,
                    front1Data.setup_spine_tilt
                  )}
                >
                  {front1Data.setup_spine_tilt}
                </TableCell>
                <TableCell align="center">{setup[1]?.maxSpineTilt}</TableCell>
                <TableCell align="center">
                  {setup[1]?.minShoulderTilt}
                </TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[1]?.minShoulderTilt,
                    setup[1]?.maxShoulderTilt,
                    front1Data.setup_shoulder_tilt
                  )}
                >
                  {front1Data.setup_shoulder_tilt}
                </TableCell>
                <TableCell align="center">
                  {setup[1]?.maxShoulderTilt}
                </TableCell>
                <TableCell align="center">
                  {front1Data.setup_stance_width_in}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default SetupFaceOnDetailed;
