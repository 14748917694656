import { makeAutoObservable } from "mobx";

class AuthStore {
  loggedIn = false;
  user = {};
  redirectURL = {};

  constructor() {
    makeAutoObservable(this);
  }

  setLoggedIn(st) {
    this.loggedIn = st;
  }

  setRedirectURL(url) {
    this.redirectURL = url;
  }

  setUser(user) {
    this.user = user;
  }
}

export default AuthStore;
