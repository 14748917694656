import React, { useContext, useEffect } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { CoachingStoreContext } from "../stores/contexts";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
var _ = require("lodash");

function getListLabelPrimary(item) {
  let dateFormat = moment(item.date.toDate()).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );
  let label = `Coaching Request From ${item.name}`;
  return label;
}

function getListLabelSecondary(item) {
  let dateFormat = moment(item.date.toDate()).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );
  let label = `${dateFormat} `;
  return label;
}

const CoachRequestsList = observer((props) => {
  const { history } = props;
  const coachStore = useContext(CoachingStoreContext);

  function isPaidStudent(req) {
    console.log("in paid student ", req);
    console.log("stucoach ", coachStore.paidStudents);
    let ind = _.findIndex(coachStore.paidStudents, (o) => {
      return o.studentEmail == req.email;
    });

    if (ind === -1) {
      return false;
    }
    return true;
  }
  return (
    <div>
      <ListSubheader component="div" id="nested-list-subheader">
        Incoming Coaching Requests - {coachStore.coachingRequests.length}{" "}
        Requests
      </ListSubheader>
      <ListSubheader component="div" id="nested-list-subheader">
        Please click on a request to view video and data.
      </ListSubheader>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        {coachStore.coachingRequests.map((req) => (
          <ListItem
            button
            key={req.id}
            onClick={() => history.push("/coach/" + req.id)}
          >
            {isPaidStudent(req) && (
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
            )}

            <ListItemText
              primary={getListLabelPrimary(req)}
              secondary={getListLabelSecondary(req)}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
});

export default CoachRequestsList;
