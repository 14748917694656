import { useHistory } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../stores/contexts";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import * as firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const TopBar = observer(() => {
  let history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();

  return (
    <AppBar position="static" style={{ backgroundColor: "#FF5722" }}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          JoyMotion GOLF
        </Typography>
        {auth.loggedIn ? (
          <Button
            color="inherit"
            onClick={() => {
              firebase.auth().signOut();
              history.push("/login");
            }}
          >
            Logout
          </Button>
        ) : (
          <Button color="inherit">Login</Button>
        )}
      </Toolbar>
    </AppBar>
  );
});

export default TopBar;
