import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebase from "firebase";
import React, { useContext } from "react";
import { AuthContext } from "../stores/contexts";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/dashboard",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
  ],
};

const LoginPage = observer(() => {
  const auth = useContext(AuthContext);
  let history = useHistory();
  let location = useLocation();

  console.log("location ", auth.redirectURL);
  let { from } = location.state || { from: { pathname: "/" } };
  let login = () => {};

  // Configure FirebaseUI.

  return (
    <div style={{ marginTop: 50 }}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
});

export default LoginPage;
