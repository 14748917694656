import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Player } from "video-react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from "recharts";

var _ = require("lodash");

const getDistance = function (x1, y1, x2, y2) {
  var xs = x2 - x1,
    ys = y2 - y1;

  xs *= xs;
  ys *= ys;

  return Math.sqrt(xs + ys);
};

const getRotatePercentage = function (startDist, currentDist) {
  return ((startDist - currentDist) / startDist) * 100;
};

const HipMovement = observer((props) => {
  const { analysisData, front0Data, front1Data } = props;
  const [hips, setHips] = useState([]);
  const [hip1, setHip1] = useState([]);
  const [hip2, setHip2] = useState([]);

  useEffect(() => {
    let recs = [];
    for (let i in analysisData) {
      let analysis = analysisData[i];
      if (analysis.type === "front" && analysis.presetName === "Hip Movement") {
        let ind = _.findIndex(recs, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1) {
          recs.push(analysis);
        }
      }
    }

    setHips(recs);
  }, [analysisData]);

  useEffect(() => {
    if (front0Data) {
      let recs = [];
      let startX = 0;
      let startDist = 0;
      for (let i in front0Data.data) {
        let dat = front0Data.data[i];
        if (dat.count > 90) {
          break;
        }
        let midPoint = Math.round((dat.l_hip_x + dat.r_hip_x) / 2);
        let hipDist = getDistance(
          dat.l_hip_x,
          dat.l_hip_y,
          dat.r_hip_x,
          dat.r_hip_y
        );
        if (dat.count === -1) {
          startX = midPoint;
          startDist = hipDist;
        }

        let obj = {
          count: dat.count,
          l_x: dat.l_hip_x,
          l_y: dat.l_hip_y,
          r_x: dat.r_hip_x,
          r_y: dat.r_hip_y,
          distFromStart: midPoint - startX,
          hipRotate: getRotatePercentage(startDist, hipDist),
        };
        if (obj.distFromStart < -100 || obj.distFromStart > 100) {
          continue;
        }
        recs.push(obj);
      }
      setHip1(recs);
    }
  }, [front0Data]);

  useEffect(() => {
    if (front1Data) {
      let recs = [];
      let startX = 0;
      let startDist = 0;
      for (let i in front1Data.data) {
        let dat = front1Data.data[i];
        if (dat.count > 90) {
          break;
        }
        let midPoint = Math.round((dat.l_hip_x + dat.r_hip_x) / 2);
        let hipDist = getDistance(
          dat.l_hip_x,
          dat.l_hip_y,
          dat.r_hip_x,
          dat.r_hip_y
        );
        if (dat.count === -1) {
          startX = midPoint;
          startDist = hipDist;
        }
        let obj = {
          count: dat.count,
          l_x: dat.l_hip_x,
          l_y: dat.l_hip_y,
          r_x: dat.r_hip_x,
          r_y: dat.r_hip_y,
          distFromStart: midPoint - startX,
          hipRotate: getRotatePercentage(startDist, hipDist),
        };
        if (obj.distFromStart < -100 || obj.distFromStart > 100) {
          continue;
        }
        recs.push(obj);
      }
      setHip2(recs);
    }
  }, [front1Data]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 0 }}
    >
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Detailed Breakdown</Typography>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Hip Movement</Typography>

      {hips && (
        <Grid container justify="center" spacing={10}>
          {hips.map((data, index) => (
            <Grid item key={index}>
              {data.completed && (
                <Player
                  playsInline
                  src={data.video_url}
                  fluid={false}
                  height={600}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Grid container justify="center" spacing={10}>
        <Grid item>
          <Typography>Swing 1</Typography>
          <LineChart
            layout="vertical"
            width={500}
            height={300}
            data={hip1}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              dataKey="count"
              label={{
                value: "Frame Count",
                angle: -90,
                position: "insideLeft",
              }}
              type="category"
            />
            <Tooltip />
            <Legend />
            <Line
              dataKey="distFromStart"
              name={"Hip Movement"}
              stroke="#8884d8"
            />
          </LineChart>
        </Grid>

        <Grid item>
          <Typography>Swing 2</Typography>
          <LineChart
            layout="vertical"
            width={500}
            height={300}
            data={hip2}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              dataKey="count"
              label={{
                value: "Frame Count",
                angle: -90,
                position: "insideLeft",
              }}
              type="category"
            />
            <Tooltip />
            <Legend />
            <Line
              dataKey="distFromStart"
              name={"Hip Movement"}
              stroke="#8884d8"
            />
          </LineChart>
        </Grid>
      </Grid>
    </Box>
  );
});

export default HipMovement;
