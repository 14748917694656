import Box from "@material-ui/core/Box";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import SetupFaceOnCoachTolerances from "./SetupFaceOn";
import SetupDTLCoachTolerances from "./SetupDTL";
import SpineFaceOnCoachTolerances from "./SpineFaceOn";
import SpineDTLCoachTolerances from "./SpineDTL";
import HipTols from "./HipTols";
import SwingTolerances from "./SwingTol";
import TempoTolerances from "./TempoTol";
import CoachComment from "./StudentComment";
import { CoachingStoreContext } from "../stores/contexts";
import HipMovement from "./HipMovement";

const StudentResponse = observer((props) => {
  const { analysisData, id } = props;
  const [value, setValue] = React.useState("setup_face_on");
  const [faceOnTols, setFaceOnTols] = useState();
  const [dtlTols, setDTLTols] = useState();
  const coachingStore = useContext(CoachingStoreContext);

  const handleChange = (event) => {
    setValue(event.target.value);
    coachingStore.setCoachingValues("recommendedDrill", event.target.value);
  };

  useEffect(() => {
    let faceOnSet = false;
    let dtlSet = false;

    for (let i in analysisData) {
      let dat = analysisData[i];
      if (!faceOnSet && dat.type === "front") {
        setFaceOnTols(dat);
      } else if (!dtlSet && dat.type === "dtl") {
        setDTLTols(dat);
      }
    }
  }, [analysisData]);

  useEffect(() => {
    console.log("face on ", faceOnTols);
  }, [faceOnTols]);

  useEffect(() => {
    console.log("dtl ", dtlTols);
  }, [dtlTols]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20 }}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Select Fundamental for Student to Work On
        </FormLabel>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value="setup_face_on"
            control={<Radio />}
            label="Setup Face On"
          />
          <FormControlLabel
            value="setup_dtl"
            control={<Radio />}
            label="Setup Down The Line"
          />
          <FormControlLabel
            value="spine_face_on"
            control={<Radio />}
            label="Stable Spine Face On"
          />
          <FormControlLabel
            value="spine_dtl"
            control={<Radio />}
            label="Stable Spine DTL"
          />
          <FormControlLabel
            value="hip"
            control={<Radio />}
            label="Hip Movement"
          />
          <FormControlLabel
            value="swing"
            control={<Radio />}
            label="Swing Plane"
          />
          <FormControlLabel value="tempo" control={<Radio />} label="Tempo" />
        </RadioGroup>
      </FormControl>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <h5>
        Please modify the tolerances for the drill that you want the student to
        work on.
      </h5>
      {value === "setup_face_on" && (
        <SetupFaceOnCoachTolerances faceOnTols={faceOnTols} />
      )}
      {value === "setup_dtl" && <SetupDTLCoachTolerances dtlTols={dtlTols} />}
      {value === "spine_face_on" && (
        <Box>
          <SpineFaceOnCoachTolerances faceOnTols={faceOnTols} />
        </Box>
      )}
      {value === "spine_dtl" && (
        <Box>
          <SpineDTLCoachTolerances dtlTols={dtlTols} />
        </Box>
      )}
      {value === "hip" && (
        <Box>
          <HipTols faceOnTols={faceOnTols} />
        </Box>
      )}
      {value === "swing" && (
        <Box>
          <SwingTolerances dtlTols={dtlTols} />
        </Box>
      )}
      {value === "tempo" && (
        <Box>
          <TempoTolerances />
        </Box>
      )}
      <hr
        style={{
          marginTop: 30,
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <CoachComment id={id} />
    </Box>
  );
});

export default StudentResponse;
