import { useHistory, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { observer } from "mobx-react-lite";
import { Player } from "video-react";
import "vimond-replay/index.css";
import { AnalysisContext } from "../stores/contexts";
import FaceOnResults from "../components/FaceOnResults";
import DTLResults from "../components/DTLResults";

const Coach = observer((props) => {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const db = firebase.firestore();
  const storage = firebase.storage();
  let { id } = useParams();

  const {
    front0,
    front1,
    dtl0,
    dtl1,
    reqData,
    analysisData,
    setupFaceOn,
    spineTilt,
    hip,
    tempo,
    setupDTL,
    spineAngle,
    swingPlane,
    setValue,
  } = props;

  const analysis = useContext(AnalysisContext);
  let history = useHistory();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 10 }}
    >
      <h1>Diagnostics Results for {reqData?.name}</h1>

      {analysisData !== null && (
        <Grid container justify="space-evenly" stye={{}}>
          <Grid item style={{}}>
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
              style={{ marginTop: 10 }}
            >
              <h2>Face On</h2>
              <FaceOnResults
                setupFaceOn={setupFaceOn}
                spineTilt={spineTilt}
                hip={hip}
                tempo={tempo}
                setValue={setValue}
              />
            </Box>
          </Grid>

          <Grid item style={{ marginLeft: 0 }}>
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
              style={{ marginTop: 10 }}
            >
              <h2 style={{ marginTop: 10 }}>Down The Line</h2>
              <DTLResults
                setupDTL={setupDTL}
                spineAngle={spineAngle}
                swingPlane={swingPlane}
                setValue={setValue}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <h1>Steps to complete online coaching request</h1>
      <h3>1) View videos and data of each swing fundamental</h3>
      <h3>2) Respond to student with drill to work on</h3>
    </Box>
  );
});
export default Coach;
