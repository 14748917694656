import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Player } from "video-react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

var _ = require("lodash");

const SpineFaceOnDetailed = observer((props) => {
  const { analysisData, front0Data, front1Data, dtl0Data, dtl1Data } = props;
  const [setup, setSetup] = useState([]);
  const [dtl, setDtl] = useState([]);
  const [spineTilts, setSpineTilts] = useState([]);
  const [spineAngles, setSpineAngles] = useState([]);
  const [s1TiltAddress, setS1TiltAddress] = useState();
  const [s1TiltTop, setS1TiltTop] = useState();
  const [s1TiltImpact, setS1TiltImpact] = useState();
  const [s2TiltAddress, setS2TiltAddress] = useState();
  const [s2TiltTop, setS2TiltTop] = useState();
  const [s2TiltImpact, setS2TiltImpact] = useState();
  const [s1AngleAddress, setS1AngleAddress] = useState();
  const [s1AngleTop, setS1AngleTop] = useState();
  const [s1AngleImpact, setS1AngleImpact] = useState();
  const [s2AngleAddress, setS2AngleAddress] = useState();
  const [s2AngleTop, setS2AngleTop] = useState();
  const [s2AngleImpact, setS2AngleImpact] = useState();

  function getColor(min, max, val) {
    if (min && max && val) {
      if (parseInt(min) <= parseInt(val) && parseInt(val) <= parseInt(max)) {
        return { backgroundColor: "green", color: "white" };
      } else {
        return { backgroundColor: "red", color: "white" };
      }
    }
  }

  useEffect(() => {
    let recs = [];
    let dtls = [];
    for (let i in analysisData) {
      let analysis = analysisData[i];
      if (analysis.type === "front" && analysis.presetName === "Stable Spine") {
        let ind = _.findIndex(recs, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1) {
          recs.push(analysis);
        }
      } else if (
        analysis.type === "dtl" &&
        analysis.presetName === "Stable Spine"
      ) {
        let ind = _.findIndex(dtls, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1 && dtls.length < 2) {
          dtls.push(analysis);
        }
      }
    }

    console.log("DTLS", dtls);

    setSetup(recs);
    setDtl(dtls);
  }, [analysisData]);

  useEffect(() => {
    if (front0Data && front1Data) {
      let spine = [];
      let data0 = front0Data.data;
      let data1 = front1Data.data;

      for (let i in data0) {
        let dat0 = data0[i];

        if (dat0.count > front0Data.impact + 10) {
          break;
        }

        let ind = _.findIndex(data1, (o) => {
          return o.count === dat0.count;
        });

        let obj = {
          count: dat0.count,
          swing1: dat0.spine_tilt,
          swing2: data1[ind].spine_tilt,
        };

        spine.push(obj);
      }

      setSpineTilts(spine);
    }
  }, [front0Data, front1Data]);

  useEffect(() => {
    if (dtl0Data && dtl1Data) {
      let spine = [];
      let data0 = dtl0Data.data;
      let data1 = dtl1Data.data;

      for (let i in data0) {
        let dat0 = data0[i];

        if (dat0.count > dtl0Data.impact + 10) {
          break;
        }

        let ind = _.findIndex(data1, (o) => {
          return o.count === dat0.count;
        });

        let obj = {
          count: dat0.count,
          swing1: dat0?.spine_angle,
          swing2: data1[ind]?.spine_angle,
        };

        spine.push(obj);
      }

      setSpineAngles(spine);
    }
  }, [dtl0Data, dtl1Data]);

  useEffect(() => {
    for (let i in spineTilts) {
      let tilt = spineTilts[i];
      if (tilt.count === front0Data.swing_start) {
        setS1TiltAddress(tilt.swing1);
      }
      if (tilt.count === front1Data.swing_start) {
        setS2TiltAddress(tilt.swing2);
      }

      if (tilt.count === front0Data.top_bs) {
        setS1TiltTop(tilt.swing1);
      }
      if (tilt.count === front1Data.top_bs) {
        setS2TiltTop(tilt.swing2);
      }

      if (tilt.count === front0Data.impact) {
        setS1TiltImpact(tilt.swing1);
      }
      if (tilt.count === front1Data.impact) {
        setS2TiltImpact(tilt.swing2);
      }
    }
  }, [spineTilts]);

  useEffect(() => {
    for (let i in spineAngles) {
      let angle = spineAngles[i];
      if (angle.count === dtl0Data.swing_start) {
        setS1AngleAddress(angle.swing1);
      }
      if (angle.count === dtl1Data.swing_start) {
        setS2AngleAddress(angle.swing2);
      }

      if (angle.count === dtl0Data.top_bs) {
        setS1AngleTop(angle.swing1);
      }
      if (angle.count === dtl1Data.top_bs) {
        setS2AngleTop(angle.swing2);
      }

      if (angle.count === dtl0Data.impact) {
        setS1AngleImpact(angle.swing1);
      }
      if (angle.count === dtl1Data.impact) {
        setS2AngleImpact(angle.swing2);
      }
    }
  }, [spineAngles]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 10 }}
    >
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Detailed Breakdown</Typography>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Stable Spine Face On</Typography>

      <Grid container justify="center" spacing={10}>
        {setup.map((data, index) => (
          <Grid item key={index}>
            {data.completed && (
              <Player
                playsInline
                src={data.video_url}
                fluid={false}
                height={600}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      {front0Data && front1Data && (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          alignContent={"center"}
          style={{ marginTop: 10 }}
        >
          <Table>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell>Swing</TableCell>
                  <TableCell>Min</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Max</TableCell>
                  <TableCell>Top of Backswing</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell>Tilt Increase Needed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Swing 1</TableCell>
                  <TableCell>{setup[0]?.minSpineTilt}</TableCell>
                  <TableCell
                    style={getColor(
                      setup[0]?.minSpineTilt,
                      setup[0]?.maxSpineTilt,
                      s1TiltAddress
                    )}
                  >
                    {s1TiltAddress}
                  </TableCell>
                  <TableCell>{setup[0]?.maxSpineTilt}</TableCell>

                  <TableCell>{s1TiltTop}</TableCell>
                  <TableCell
                    style={getColor(
                      parseInt(s1TiltAddress) +
                        parseInt(setup[0]?.spineTiltIncreaseDS),
                      parseInt(s1TiltAddress) + 50,
                      parseInt(s1TiltImpact)
                    )}
                  >
                    {s1TiltImpact}
                  </TableCell>
                  <TableCell>{setup[0]?.spineTiltIncreaseDS}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Swing 2</TableCell>
                  <TableCell>{setup[1]?.minSpineTilt}</TableCell>
                  <TableCell
                    style={getColor(
                      setup[1]?.minSpineTilt,
                      setup[1]?.maxSpineTilt,
                      s2TiltAddress
                    )}
                  >
                    {s2TiltAddress}
                  </TableCell>
                  <TableCell>{setup[1]?.maxSpineTilt}</TableCell>
                  <TableCell>{s2TiltTop}</TableCell>
                  <TableCell
                    style={getColor(
                      parseInt(s2TiltAddress) +
                        parseInt(setup[1]?.spineTiltIncreaseDS),
                      parseInt(s2TiltAddress) + 50,
                      parseInt(s2TiltImpact)
                    )}
                  >
                    {s2TiltImpact}
                  </TableCell>
                  <TableCell>{setup[1]?.spineTiltIncreaseDS}</TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          </Table>
        </Box>
      )}

      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Grid container spacing={2}>
        {front0Data && (
          <Grid item>
            <LineChart
              width={600}
              height={400}
              data={spineTilts}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="count" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="swing1"
                stroke="blue"
                activeDot={{ r: 8 }}
              />

              <ReferenceLine
                x={front0Data.swing_start}
                stroke="green"
                label="Start"
              />
              <ReferenceLine x={front0Data.top_bs} stroke="green" label="Top" />
              <ReferenceLine
                x={front0Data.impact}
                stroke="blue"
                label="Impact"
              />
            </LineChart>
          </Grid>
        )}
        {front1Data && (
          <Grid item>
            <LineChart
              width={600}
              height={400}
              data={spineTilts}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="count" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Line
                type="monotone"
                dataKey="swing2"
                stroke="red"
                activeDot={{ r: 8 }}
              />

              <ReferenceLine
                x={front1Data.swing_start}
                stroke="blue"
                label="Start"
              />
              <ReferenceLine x={front1Data.top_bs} stroke="green" label="Top" />
              <ReferenceLine
                x={front1Data.impact}
                stroke="blue"
                label="Impact"
              />
            </LineChart>
          </Grid>
        )}
      </Grid>

      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Stable Spine DTL</Typography>
      <Grid container justify="center" spacing={10}>
        {dtl.map((data, index) => (
          <Grid item key={index}>
            {data.completed && (
              <Player
                playsInline
                src={data.video_url}
                fluid={false}
                height={600}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Grid container justify="center" spacing={2}>
        {dtl0Data && dtl1Data && (
          <Grid item>
            <LineChart
              width={600}
              height={400}
              data={spineAngles}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="count" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="swing1"
                stroke="blue"
                activeDot={{ r: 8 }}
              />

              <ReferenceLine
                x={dtl0Data.swing_start}
                stroke="green"
                label="Start"
              />
              <ReferenceLine x={dtl0Data.top_bs} stroke="green" label="Top" />
              <ReferenceLine x={dtl0Data.impact} stroke="blue" label="Impact" />
            </LineChart>
          </Grid>
        )}
        {dtl1Data && (
          <Grid item>
            <LineChart
              width={600}
              height={400}
              data={spineAngles}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="count" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Line
                type="monotone"
                dataKey="swing2"
                stroke="red"
                activeDot={{ r: 8 }}
              />

              <ReferenceLine
                x={dtl1Data.swing_start}
                stroke="blue"
                label="Start"
              />
              <ReferenceLine x={dtl1Data.top_bs} stroke="green" label="Top" />
              <ReferenceLine x={dtl1Data.impact} stroke="blue" label="Impact" />
            </LineChart>
          </Grid>
        )}
      </Grid>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      {dtl0Data && dtl1Data && (
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          alignContent={"center"}
          style={{ marginTop: 10 }}
        >
          <Table>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell>Swing</TableCell>
                  <TableCell>Min Address</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Max Address</TableCell>
                  <TableCell>Top of Backswing</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell>+/- During Swing</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Swing 1</TableCell>
                  <TableCell>{dtl[0]?.minSpineAngle}</TableCell>
                  <TableCell
                    style={getColor(
                      dtl[0]?.minSpineAngle,
                      dtl[0]?.maxSpineAngle,
                      s1AngleAddress
                    )}
                  >
                    {s1AngleAddress}
                  </TableCell>
                  <TableCell>{dtl[0]?.maxSpineAngle}</TableCell>
                  <TableCell
                    style={getColor(
                      parseInt(s1AngleAddress) -
                        parseInt(dtl[0]?.spineAngleSwing),
                      parseInt(s1AngleAddress) +
                        parseInt(dtl[0]?.spineAngleSwing),
                      s1AngleTop
                    )}
                  >
                    {s1AngleTop}
                  </TableCell>
                  <TableCell
                    style={getColor(
                      parseInt(s1AngleAddress) -
                        parseInt(dtl[0]?.spineAngleSwing),
                      parseInt(s1AngleAddress) +
                        parseInt(dtl[0]?.spineAngleSwing),
                      s1AngleImpact
                    )}
                  >
                    {s1AngleImpact}
                  </TableCell>
                  <TableCell>{dtl[0]?.spineAngleSwing}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Swing 2</TableCell>
                  <TableCell>{dtl[1]?.minSpineAngle}</TableCell>
                  <TableCell
                    style={getColor(
                      dtl[1]?.minSpineAngle,
                      dtl[1]?.maxSpineAngle,
                      s2AngleAddress
                    )}
                  >
                    {s2AngleAddress}
                  </TableCell>
                  <TableCell>{dtl[1]?.maxSpineAngle}</TableCell>
                  <TableCell
                    style={getColor(
                      parseInt(s2AngleAddress) -
                        parseInt(dtl[1]?.spineAngleSwing),
                      parseInt(s2AngleAddress) +
                        parseInt(dtl[1]?.spineAngleSwing),
                      s2AngleTop
                    )}
                  >
                    {s2AngleTop}
                  </TableCell>
                  <TableCell
                    style={getColor(
                      parseInt(s2AngleAddress) -
                        parseInt(dtl[1]?.spineAngleSwing),
                      parseInt(s2AngleAddress) +
                        parseInt(dtl[1]?.spineAngleSwing),
                      s2AngleImpact
                    )}
                  >
                    {s2AngleImpact}
                  </TableCell>
                  <TableCell>{dtl[1]?.spineAngleSwing}</TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          </Table>
        </Box>
      )}
    </Box>
  );
});

export default SpineFaceOnDetailed;
