import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Player } from "video-react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

var _ = require("lodash");

const Tempo = observer((props) => {
  const { analysisData, temp0Data, temp1Data } = props;
  const [tempos, setTempos] = useState([]);
  const [tempo1, setTempo1] = useState({});
  const [tempo2, setTempo2] = useState({});

  useEffect(() => {
    let recs = [];
    for (let i in analysisData) {
      let analysis = analysisData[i];
      if (
        analysis.type === "front" &&
        analysis.presetName === "Tempo (Only Face On)"
      ) {
        let ind = _.findIndex(recs, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1) {
          console.log("hips ", analysis);
          recs.push(analysis);
        }
      }
    }

    setTempos(recs);
  }, [analysisData]);

  useEffect(() => {
    if (temp0Data) {
      let obj = {
        start: temp0Data.swing_start,
        top: temp0Data.top_bs,
        impact: temp0Data.impact,
        backswing: (temp0Data.top_bs - temp0Data.swing_start) / 30,
        downswing: (temp0Data.impact - temp0Data.top_bs) / 30,
      };
      obj["tempo"] = (obj.backswing / obj.downswing).toFixed(1);
      setTempo1(obj);
    }
  }, [temp0Data]);

  useEffect(() => {
    if (temp1Data) {
      let obj = {
        start: temp1Data.swing_start,
        top: temp1Data.top_bs,
        impact: temp1Data.impact,
        backswing: (temp1Data.top_bs - temp1Data.swing_start) / 30,
        downswing: (temp1Data.impact - temp1Data.top_bs) / 30,
      };
      obj["tempo"] = (obj.backswing / obj.downswing).toFixed(1);
      setTempo2(obj);
    }
  }, [temp1Data]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 0 }}
    >
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Detailed Breakdown</Typography>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Typography>Tempo</Typography>

      {tempos && (
        <Grid container justify="center" spacing={10}>
          {tempos.map((data, index) => (
            <Grid item key={index}>
              {data.completed && (
                <Player
                  playsInline
                  src={data.video_url}
                  fluid={false}
                  height={600}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        style={{ marginTop: 10 }}
      >
        <Table>
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>Swing</TableCell>
                <TableCell>Backswing (secs)</TableCell>
                <TableCell>Downswing (secs)</TableCell>
                <TableCell>Tempo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Swing 1</TableCell>
                <TableCell>{tempo1?.backswing?.toFixed(2)}</TableCell>
                <TableCell>{tempo1?.downswing?.toFixed(2)}</TableCell>
                <TableCell>{tempo1.tempo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Swing 2</TableCell>
                <TableCell>{tempo2?.backswing?.toFixed(2)}</TableCell>
                <TableCell>{tempo2?.downswing?.toFixed(2)}</TableCell>
                <TableCell>{tempo2.tempo}</TableCell>
              </TableRow>
            </TableBody>
          </TableContainer>
        </Table>
      </Box>
    </Box>
  );
});

export default Tempo;
