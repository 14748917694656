import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import greenCheck from "../assets/GreenCheck.jpg";
import yellowCheck from "../assets/yellow_check.jpg";
import redX from "../assets/RedXMark.jpg";
import Box from "@material-ui/core/Box";
import React from "react";
import Button from "@material-ui/core/Button";

function FaceOnResults(props) {
  const { setupFaceOn, spineTilt, hip, tempo, setValue } = props;

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Setup
            </TableCell>
            <TableCell align="right">
              {setupFaceOn == 2 && (
                <img src={greenCheck} width={25} height={25} />
              )}
              {setupFaceOn == 1 && (
                <img src={yellowCheck} width={25} height={25} />
              )}

              {setupFaceOn == 0 && <img src={redX} width={25} height={25} />}
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue(1);
                }}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Stable Spine
            </TableCell>
            <TableCell align="right">
              {spineTilt == 2 && (
                <img src={greenCheck} width={25} height={25} />
              )}
              {spineTilt == 1 && (
                <img src={yellowCheck} width={25} height={25} />
              )}
              {spineTilt == 0 && <img src={redX} width={25} height={25} />}
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue(2);
                }}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Hip Movement
            </TableCell>
            <TableCell align="right">
              {hip == 2 && <img src={greenCheck} width={25} height={25} />}
              {hip == 1 && <img src={yellowCheck} width={25} height={25} />}

              {hip == 0 && <img src={redX} width={25} height={25} />}
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue(3);
                }}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Tempo
            </TableCell>
            <TableCell align="right">
              {tempo == 2 && <img src={greenCheck} width={25} height={25} />}
              {tempo == 1 && <img src={yellowCheck} width={25} height={25} />}

              {tempo == 0 && <img src={redX} width={25} height={25} />}
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue(5);
                }}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FaceOnResults;
