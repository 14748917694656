import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AnalysisContext } from "../stores/contexts";
import { Replay } from "vimond-replay";
import "vimond-replay/index.css";
import * as firebase from "firebase";
import { Redirect } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { CSVLink } from "react-csv";
import moment from "moment";
import "../../node_modules/video-react/dist/video-react.css";
import { Typography } from "@material-ui/core";
import { Player } from "video-react";

const Analysis = observer(() => {
  const analysis = useContext(AnalysisContext);
  const db = firebase.firestore();

  const [videoTime, setVideoTime] = useState();

  useEffect(() => {
    (async () => {
      let id = analysis.selectedAnalysis.mvpVideoId;
      console.log("mvp id ", id);
      if (id) {
        let dataRef = await db.collection("processed_pose_data").doc(id).get();

        if (dataRef.exists) {
          let data = dataRef.data();

          analysis.setSelectedPoseData(data);
          console.log("pose data ", data);
        }
      }
    })();
  }, [analysis, db]);

  const renderSpineTilt = () => (
    <Box
      justifyContent={"center"}
      alignItems="center"
      style={{ width: "100%" }}
    >
      <h3>
        {analysis.selectedAnalysis.type === "dtl"
          ? "Spine Angle"
          : "Spine Tilt"}
      </h3>
      <LineChart
        width={600}
        height={300}
        data={analysis.poseArray}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <ReferenceLine
          y={
            analysis.selectedAnalysis.type === "dtl"
              ? analysis?.poseArray[0]?.spine_angle
              : analysis?.poseArray[0]?.spine_tilt
          }
          label="Setup"
          stroke="red"
          strokeDasharray="3 3"
        />
        <Line
          type="monotone"
          dataKey={
            analysis.selectedAnalysis.type === "dtl"
              ? "spine_angle"
              : "spine_tilt"
          }
          stroke="#8884d8"
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="count" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </Box>
  );

  const renderSwingPlane = () => (
    <Box
      justifyContent={"center"}
      alignItems="center"
      style={{ width: "100%" }}
    >
      <h3>Swing Plane</h3>
      <LineChart
        width={600}
        height={300}
        data={analysis.poseArray}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <ReferenceLine
          y={analysis?.poseArray[0]?.swing_plane_angle}
          label="Setup"
          stroke="red"
          strokeDasharray="3 3"
        />
        <Line type="monotone" dataKey={"swing_plane_angle"} stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="count" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </Box>
  );

  const renderHipDistance = () => (
    <Box
      justifyContent={"center"}
      alignItems="center"
      style={{ width: "100%" }}
    >
      <h3>Hip Rotation</h3>
      <LineChart
        width={600}
        height={300}
        data={analysis.poseArray}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <ReferenceLine
          y={analysis?.poseArray[0]?.hip_distance}
          label="Setup"
          stroke="red"
          strokeDasharray="3 3"
        />
        <Line type="monotone" dataKey={"hip_distance"} stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="count" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </Box>
  );

  if (!analysis.selectedAnalysis.mvpVideoId) {
    return <Redirect to={"/dashboard"} />;
  } else {
    console.log("ANALYSIS ", analysis.poseArray);
    return (
      <Box flex={1} justifyContent={"center"} alignItems="center">
        <CSVLink
          data={analysis.poseArray}
          filename={`${analysis.selectedAnalysis.presetName}_${moment(
            analysis.selectedAnalysis.time.toDate()
          ).format("dddd, MMMM Do YYYY, h:mm:ss a")}.csv`}
        >
          Download Data
        </CSVLink>
        <Grid container justify="space-evenly" spacing={1}>
          <Grid item>
            {analysis.poseLength > 0 ? renderSpineTilt() : <div></div>}
          </Grid>
          <Grid item>
            {analysis.selectedAnalysis.type === "dtl"
              ? renderSwingPlane()
              : renderHipDistance()}
          </Grid>
        </Grid>

        <Grid container justify="space-evenly" spacing={1}>
          <Grid item>
            <Box
              flex={1}
              justifyContent={"center"}
              alignItems="center"
              style={{ width: 600, height: 900 }}
            >
              <Player
                playsInline
                src={analysis.selectedAnalysis.raw_video_url}
                fluid={false}
                height={"90%"}
              />
              <Player
                playsInline
                src={analysis.selectedAnalysis.video_url}
                fluid={false}
                height={"90%"}
              />
              {/*<Replay*/}
              {/*  source={analysis.selectedAnalysis.video_url}*/}
              {/*  initialPlaybackProps={{ isPaused: true }}*/}
              {/*/>*/}
            </Box>
          </Grid>
          <Grid item>
            <Box
              justifyContent={"center"}
              alignItems="center"
              style={{ width: 600, height: 400 }}
            >
              <Typography>Add Comment at Time {videoTime}</Typography>
              <TextField
                id="outlined-multiline-static"
                label="Multiline"
                multiline
                rows={4}
                defaultValue="Default Value"
                variant="outlined"
                style={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ width: "100%", marginTop: 30 }}
              >
                Add Comment
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
});

export default Analysis;
