import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CoachingStoreContext } from "../stores/contexts";

const SetupFaceOnCoachTolerances = observer((props) => {
  const { faceOnTols } = props;
  const [minStanceWidth, setMinStanceWidth] = useState();
  const [maxStanceWidth, setMaxStanceWidth] = useState();
  const [minShoulderTilt, setMinShoulderTilt] = useState();
  const [maxShoulderTilt, setMaxShoulderTilt] = useState();
  const [minSpineTilt, setMinSpineTilt] = useState();
  const [maxSpineTilt, setMaxSpineTilt] = useState();
  const coachingStore = useContext(CoachingStoreContext);

  useEffect(() => {
    if (faceOnTols) {
      setMinStanceWidth(faceOnTols.minStanceWidth);
      setMaxStanceWidth(faceOnTols.maxStanceWidth);
      setMinShoulderTilt(faceOnTols.minShoulderTilt);
      setMaxShoulderTilt(faceOnTols.maxShoulderTilt);
      setMinSpineTilt(faceOnTols.minSpineTilt);
      setMaxSpineTilt(faceOnTols.maxSpineTilt);

      coachingStore.setCoachingValues(
        "minStanceWidth",
        faceOnTols.minStanceWidth
      );
      coachingStore.setCoachingValues(
        "maxStanceWidth",
        faceOnTols.maxStanceWidth
      );
      coachingStore.setCoachingValues(
        "minShoulderTilt",
        faceOnTols.minShoulderTilt
      );
      coachingStore.setCoachingValues(
        "maxShoulderTilt",
        faceOnTols.maxShoulderTilt
      );
      coachingStore.setCoachingValues("minSpineTilt", faceOnTols.minSpineTilt);
      coachingStore.setCoachingValues("maxSpineTilt", faceOnTols.maxSpineTilt);
    }
  }, [faceOnTols]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20 }}
    >
      <h1>Setup Face On</h1>
      <Grid container spacing={5}>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Stance Width"
              value={minStanceWidth}
              onChange={(e) => {
                setMinStanceWidth(e.target.value);
                coachingStore.setCoachingValues(
                  "minStanceWidth",
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Stance Width"
              value={maxStanceWidth}
              onChange={(e) => {
                setMaxStanceWidth(e.target.value);
                coachingStore.setCoachingValues(
                  "maxStanceWidth",
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Shoulder Tilt"
              value={minShoulderTilt}
              onChange={(e) => {
                setMinShoulderTilt(e.target.value);
                coachingStore.setCoachingValues(
                  "minShoulderTilt",
                  e.target.value
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Shoulder Tilt"
              value={maxShoulderTilt}
              onChange={(e) => {
                setMaxShoulderTilt(e.target.value);
                coachingStore.setCoachingValues(
                  "maxShoulderTilt",
                  e.target.value
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={7}>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Min Spine Tilt"
              value={minSpineTilt}
              onChange={(e) => {
                setMinSpineTilt(e.target.value);
                coachingStore.setCoachingValues("minSpineTilt", e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              label="Max Spine Tilt"
              value={maxSpineTilt}
              onChange={(e) => {
                setMaxSpineTilt(e.target.value);
                coachingStore.setCoachingValues("maxSpineTilt", e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default SetupFaceOnCoachTolerances;
