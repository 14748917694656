import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import { Elements } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const PaymentFailure = observer((props) => {
  let history = useHistory();
  return (
    <div>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        style={{ marginTop: 20 }}
      >
        <h1>Student Subscription Payment Failed</h1>
        <h2>Please try again</h2>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20, width: "30%" }}
          onClick={() => history.push("/")}
        >
          Back
        </Button>
      </Box>
    </div>
  );
});

export default PaymentFailure;
