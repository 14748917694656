import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as firebase from "firebase";
import {
  Elements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthContext, CoachingStoreContext } from "../stores/contexts";

const stripePromise = loadStripe(
  "pk_test_51HqqVSC7Qo9pOD8WXuetyTGf6DaYvyU1x50Mcbe1OvBP9TgHoOWl74bjw5Q80oDPqI4NQJQiZB3YZfdTR84W1sJ40009BQjTit",
  { apiVersion: "2020-08-27" }
);

const AddStudents = observer((props) => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [confirmPW, setConfirmPW] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [studentCoachId, setStudentCoachId] = useState("");
  const auth = useContext(AuthContext);
  const coach = useContext(CoachingStoreContext);

  async function onAdd() {
    console.log(email, pw, confirmPW);
    if (pw === confirmPW) {
      var createStudentFunction = firebase
        .functions()
        .httpsCallable("createStudentAccount");
      let createStudentRes = await createStudentFunction({
        email: email,
        password: pw,
        displayName: name,
        phoneNumber: phone,
      });
      console.log("createStudentRes ", createStudentRes);
      let id = await coach.createNewCoachStudent(
        createStudentRes.data.uid,
        auth.user.uid,
        name,
        phone
      );

      setStudentCoachId(id);

      setPw("");
      setConfirmPW("");
      setName("");
      setPhone("");
      alert("Student successfully added. Please enter in payment info next.");
    }
  }

  useEffect(() => {
    console.log("auth in add student ", auth.user.uid);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div>
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          alignContent={"center"}
          style={{ marginTop: 20 }}
        >
          <hr
            style={{
              borderColor: "#ff5722",
              width: "100%",
            }}
          />
          <h1>1) Add Student</h1>
          <p>
            Enter your student's email address and ask them to create a password
            to login to JoyMotion Golf with. Note this is in beta and only used
            for testing!
          </p>
          <TextField
            id="standard-basic"
            label="Student's Email"
            style={{ width: "30%" }}
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            id="standard-basic"
            label="Password"
            style={{ width: "30%" }}
            value={pw}
            type={"password"}
            onChange={(e) => {
              setPw(e.target.value);
            }}
          />
          <TextField
            id="standard-basic"
            label="Confirm Password"
            style={{ width: "30%" }}
            value={confirmPW}
            type={"password"}
            onChange={(e) => {
              setConfirmPW(e.target.value);
            }}
          />
          <TextField
            id="standard-basic"
            label="Name"
            style={{ width: "30%" }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            id="standard-basic"
            label="Phone Number"
            style={{ width: "30%" }}
            value={phone}
            type={"phone"}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20, width: "30%" }}
            onClick={() => onAdd()}
          >
            Add Student
          </Button>
          <hr
            style={{
              borderColor: "#ff5722",
              width: "100%",
            }}
          />
          <h1>2) Add Payment Information</h1>
          <p>Enter your student's payment information to complete sign up</p>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20, width: "30%" }}
            onClick={async () => {
              const stripe = await stripePromise;
              var stripeFunction = firebase
                .functions()
                .httpsCallable("newStripeSession ");
              let stripeRes = await stripeFunction({
                priceId: "price_1Hr2jMC7Qo9pOD8WUacWYjHq",
                clientReferenceId: studentCoachId,
                customerEmail: email,
              });
              console.log("in new stripe function ", stripeRes.data);
              setStudentCoachId("");
              setEmail("");
              // When the customer clicks on the button, redirect them to Checkout.
              const result = await stripe.redirectToCheckout({
                sessionId: stripeRes.data.id,
              });

              if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
              }
            }}
          >
            Add Payment Information
          </Button>
          <hr
            style={{
              borderColor: "#ff5722",
              width: "100%",
            }}
          />
        </Box>
      </div>
    </Elements>
  );
});

export default AddStudents;
