import { makeAutoObservable, action, computed } from "mobx";

class AnalysisStore {
  selectedAnalysis = {};
  selectedPoseData = {};
  poseArray = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedAnalysis(obj) {
    this.selectedAnalysis = obj;
  }

  setSelectedPoseData(obj) {
    this.selectedPoseData = obj;
    this.poseArray = obj.data;
  }

  get poseLength() {
    console.log(this.selectedAnalysis.presetName);
    return this.poseArray.length;
  }

  get spineAngles() {
    for (let x in this.poseArray) {
      let pose = this.poseArray[x];
      console.log(pose.count, pose.spine_angle);
    }
  }
}

export default AnalysisStore;
