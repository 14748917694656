import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CoachingStoreContext } from "../stores/contexts";

const SwingTolerances = observer((props) => {
  const { dtlTols } = props;
  const [overTheTopAngle, setOverTheTopAngle] = useState();
  const coachingStore = useContext(CoachingStoreContext);

  useEffect(() => {
    setOverTheTopAngle(dtlTols.overTheTopAngle);

    coachingStore.setCoachingValues("overTheTopAngle", dtlTols.overTheTopAngle);
  }, [dtlTols]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20 }}
    >
      <h1>Spine Tilt DTL</h1>
      <Grid container spacing={5} style={{ marginTop: 30 }}>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <TextField
            id="standard-basic"
            label="Allowed Over The Top Angle"
            value={overTheTopAngle}
            onChange={(e) => {
              setOverTheTopAngle(e.target.value);
              coachingStore.setCoachingValues(
                "overTheTopAngle",
                e.target.value
              );
            }}
            style={{ width: "50%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default SwingTolerances;
