import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CoachingStoreContext } from "../stores/contexts";

const SpineFaceOnCoachTolerances = observer((props) => {
  const { faceOnTols } = props;
  const [spineTiltIncrease, setSpineTiltIncrease] = useState();
  const coachingStore = useContext(CoachingStoreContext);

  useEffect(() => {
    setSpineTiltIncrease(faceOnTols.spineTiltIncreaseDS);

    coachingStore.setCoachingValues(
      "spineTiltIncrease",
      faceOnTols.spineTiltIncreaseDS
    );
  }, [faceOnTols]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 20 }}
    >
      <h1>Spine Tilt Face On</h1>
      <Grid container spacing={5} style={{ marginTop: 30 }}>
        <Grid
          container
          justify="center"
          spacing={7}
          style={{ marginBottom: 10 }}
        >
          <TextField
            id="standard-basic"
            label="Desired Spine Tilt Increase in Downswing"
            value={spineTiltIncrease}
            onChange={(e) => {
              setSpineTiltIncrease(e.target.value);
              coachingStore.setCoachingValues(
                "spineTiltIncrease",
                e.target.value
              );
            }}
            style={{ width: "50%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default SpineFaceOnCoachTolerances;
