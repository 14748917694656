import { makeAutoObservable, action, computed } from "mobx";
import * as firebase from "firebase";

class CoachingStore {
  activeCoachingID = "";
  recommendedDrill = "setup_face_on";
  minStanceWidth = 0;
  maxStanceWidth = 0;
  minShoulderTilt = 0;
  maxShoulderTilt = 0;
  minSpineTilt = 0;
  maxSpineTilt = 0;
  minArmHang = 0;
  maxArmHang = 0;
  minKneeFlex = 0;
  maxKneeFlex = 0;
  minPelvicTilt = 0;
  maxPelvicTilt = 0;
  minSpineAngle = 0;
  maxSpineAngle = 0;
  spineTiltIncrease = 0;
  spineAngleSwing = 0;
  allowableSwayAwayBS = 0;
  allowableSwayTowardBS = 0;
  allowableSwayAwayDS = 0;
  allowableSwayTowardDS = 0;
  rotationRate = 0;
  tempo = 3;
  overTheTopAngle = 0;
  coachingRequests = [];
  paidStudents = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCoachingValues(tol, value) {
    this[tol] = value;
  }

  submitCoachResponse(comments) {
    const db = firebase.firestore();
    const customDrill = {};

    if (this.recommendedDrill === "setup_face_on") {
      customDrill["minStanceWidth"] = this.minStanceWidth;
      customDrill["maxStanceWidth"] = this.maxStanceWidth;
      customDrill["minShoulderTilt"] = this.minShoulderTilt;
      customDrill["maxShoulderTilt"] = this.maxShoulderTilt;
      customDrill["minSpineTilt"] = this.minSpineTilt;
      customDrill["maxSpineTilt"] = this.maxSpineTilt;
    } else if (this.recommendedDrill === "setup_dtl") {
      customDrill["minArmHang"] = this.minArmHang;
      customDrill["maxArmHang"] = this.maxArmHang;
      customDrill["minKneeFlex"] = this.minKneeFlex;
      customDrill["maxKneeFlex"] = this.maxKneeFlex;
      customDrill["minPelvicTilt"] = this.minPelvicTilt;
      customDrill["maxPelvicTilt"] = this.maxPelvicTilt;
      customDrill["minSpineAngle"] = this.minSpineAngle;
      customDrill["maxSpineAngle"] = this.maxSpineAngle;
    } else if (this.recommendedDrill === "spine_face_on") {
      customDrill["spineTiltIncrease"] = this.spineTiltIncrease;
    } else if (this.recommendedDrill === "spine_dtl") {
      customDrill["spineAngleSwing"] = this.spineAngleSwing;
    } else if (this.recommendedDrill === "hip") {
      customDrill["allowableSwayAwayBS"] = this.allowableSwayAwayBS;
      customDrill["allowableSwayTowardBS"] = this.allowableSwayTowardBS;
      customDrill["allowableSwayAwayDS"] = this.allowableSwayAwayDS;
      customDrill["allowableSwayTowardDS"] = this.allowableSwayTowardDS;
      customDrill["rotationRate"] = this.rotationRate;
    } else if (this.recommendedDrill === "swing") {
      customDrill["overTheTopAngle"] = this.overTheTopAngle;
    } else if (this.recommendedDrill === "tempo") {
      customDrill["tempo"] = this.tempo;
    }

    db.collection("mvp_coach_analysis_request")
      .doc(this.activeCoachingID)
      .update({
        drill_type: this.recommendedDrill,
        custom_drill: customDrill,
        coach_comment: comments,
        completed: true,
      });
  }

  async createNewCoachStudent(studentId, coachId, name, phone) {
    const db = firebase.firestore();
    let docRef = await db.collection("coach_students").add({
      payment_status: "unverified",
      coachId: coachId,
      studentId: studentId,
      name: name,
      phone: phone,
    });

    return docRef.id;
  }

  async getCoachStudents(coachId) {
    let students = [];
    this.paidStudents = [];
    const db = firebase.firestore();

    let res = await db
      .collection("coach_students")
      .where("coachId", "==", coachId)
      .get();
    res.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      students.push(data);
    });

    this.paidStudents = students;

    return students;
  }
}

export default CoachingStore;
