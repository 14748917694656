import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../stores/contexts";
import { CoachingStoreContext } from "../stores/contexts";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import CoachRequestsList from "../components/CoachingRequestsList";
import AddStudents from "./AddStudent";
import ViewStudents from "./ViewStudents";
import AnalysisList from "../components/AnalysisList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function getListLabel(item) {
  let dateFormat = moment(item.date.toDate()).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );
  let label = `${dateFormat} | ${item.name}`;
  return label;
}

const Dashboard = observer(() => {
  let history = useHistory();
  const auth = useContext(AuthContext);
  const coachStore = useContext(CoachingStoreContext);
  const db = firebase.firestore();
  const [coachRequests, setCoachRequests] = useState([]);
  const [panel, setPanel] = useState(0);
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      let reqsSnap = await db
        .collection("mvp_coach_analysis_request")
        .where("coachID", "==", auth.user.uid)
        .where("completed", "==", false)
        .get();
      reqsSnap.forEach((doc) => {
        console.log("coach request ", doc.id);
        let data = doc.data();
        data.id = doc.id;
        setCoachRequests((prevArray) => [...prevArray, data]);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      coachStore.getCoachStudents(auth.user.uid);
    })();
  }, []);

  useEffect(() => {
    const sortedRequests = coachRequests.sort(
      (a, b) => b.date.toDate() - a.date.toDate()
    );
    coachStore.setCoachingValues("coachingRequests", sortedRequests);
  }, [coachRequests]);

  const panelChange = (event, newValue) => {
    setPanel(newValue);
  };

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={panel}
          onChange={panelChange}
          aria-label="simple tabs example"
        >
          <Tab label="Coaching Requests" />
          <Tab label="Add Students" />
          <Tab label={"View Students"} />
        </Tabs>
      </AppBar>
      {panel === 0 && <CoachRequestsList history={history} />}
      {panel === 1 && <AddStudents />}
      {panel === 2 && <ViewStudents />}
    </div>
  );
});

export default Dashboard;
