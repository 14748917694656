import Box from "@material-ui/core/Box";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import GaugeChart from "react-gauge-chart";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import { Player } from "video-react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

var _ = require("lodash");

const SetupDTLDetailed = observer((props) => {
  const { analysisData, dtl0Data, dtl1Data } = props;
  const [setup, setSetup] = useState([]);

  function getColor(min, max, val) {
    if (min && max && val) {
      console.log(min, max, val);
      if (parseInt(min) <= parseInt(val) && parseInt(val) <= parseInt(max)) {
        return { backgroundColor: "green", color: "white" };
      } else {
        return { backgroundColor: "red", color: "white" };
      }
    }
  }

  useEffect(() => {
    let recs = [];
    for (let i in analysisData) {
      let analysis = analysisData[i];
      if (analysis.type === "dtl" && analysis.presetName === "Setup") {
        let ind = _.findIndex(recs, (o) => {
          return o.id === analysis.id;
        });
        if (ind === -1) {
          recs.push(analysis);
        }
      }
    }

    setSetup(recs);
  }, [analysisData]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      style={{ marginTop: 10 }}
    >
      <Typography>Setup DTL</Typography>

      <Grid container justify="center" spacing={10}>
        {setup.map((data, index) => (
          <Grid item key={index}>
            {data.completed && (
              <Player
                playsInline
                src={data.video_url}
                fluid={false}
                height={600}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <hr
        style={{
          borderColor: "#ff5722",
          width: "100%",
        }}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Swing</TableCell>
              <TableCell align="center">Spine Angle Min</TableCell>
              <TableCell align="center">Spine Angle</TableCell>
              <TableCell align="center">Spine Angle Max</TableCell>
              <TableCell align="center">Knee Flex Min</TableCell>
              <TableCell align="center">Knee Flex</TableCell>
              <TableCell align="center">Knee Flex Max</TableCell>
              <TableCell align="center">Pelvic Tilt Min</TableCell>
              <TableCell align="center">Pelvic Tilt</TableCell>
              <TableCell align="center">Pelvic Tilt Max</TableCell>
              <TableCell align="center">Arm Hang Min</TableCell>
              <TableCell align="center">Arm Hang</TableCell>
              <TableCell align="center">Arm Hang Max</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dtl0Data && (
              <TableRow>
                <TableCell align="center">Swing 1</TableCell>
                <TableCell align="center">{setup[0]?.minSpineAngle}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[0]?.minSpineAngle,
                    setup[0]?.maxSpineAngle,
                    dtl0Data.setup_spine_angle
                  )}
                >
                  {dtl0Data.setup_spine_angle}
                </TableCell>
                <TableCell align="center">{setup[0]?.maxSpineAngle}</TableCell>
                <TableCell align="center">{setup[0]?.minKneeFlex}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[0]?.minKneeFlex,
                    setup[0]?.maxKneeFlex,
                    dtl0Data.setup_knee_bend
                  )}
                >
                  {dtl0Data.setup_knee_bend}
                </TableCell>
                <TableCell align="center">{setup[0]?.maxKneeFlex}</TableCell>
                <TableCell align="center">{setup[0]?.minPelvicTilt}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[0]?.minPelvicTilt,
                    setup[0]?.maxPelvicTilt,
                    dtl0Data.setup_pelvic_tilt
                  )}
                >
                  {dtl0Data.setup_pelvic_tilt}
                </TableCell>
                <TableCell align="center">{setup[0]?.maxPelvicTilt}</TableCell>
                <TableCell align="center">{setup[0]?.minArmHang}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    parseInt(setup[0]?.minArmHang),
                    parseInt(setup[0]?.maxArmHang),
                    parseInt(dtl0Data.setup_arm_hang)
                  )}
                >
                  {dtl0Data.setup_arm_hang}
                </TableCell>
                <TableCell align="center">{setup[0]?.maxArmHang}</TableCell>
              </TableRow>
            )}
            {dtl1Data && (
              <TableRow>
                <TableCell align="center">Swing 2</TableCell>
                <TableCell align="center">{setup[1]?.minSpineAngle}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[1]?.minSpineAngle,
                    setup[1]?.maxSpineAngle,
                    dtl1Data.setup_spine_angle
                  )}
                >
                  {dtl1Data.setup_spine_angle}
                </TableCell>
                <TableCell align="center">{setup[1]?.maxSpineAngle}</TableCell>
                <TableCell align="center">{setup[1]?.minKneeFlex}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[1]?.minKneeFlex,
                    setup[1]?.maxKneeFlex,
                    dtl1Data.setup_knee_bend
                  )}
                >
                  {dtl1Data.setup_knee_bend}
                </TableCell>
                <TableCell align="center">{setup[1]?.maxKneeFlex}</TableCell>
                <TableCell align="center">{setup[1]?.minPelvicTilt}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[1]?.minPelvicTilt,
                    setup[1]?.maxPelvicTilt,
                    dtl1Data.setup_pelvic_tilt
                  )}
                >
                  {dtl1Data.setup_pelvic_tilt}
                </TableCell>
                <TableCell align="center">{setup[1]?.maxPelvicTilt}</TableCell>
                <TableCell align="center">{setup[1]?.minArmHang}</TableCell>
                <TableCell
                  align="center"
                  style={getColor(
                    setup[1]?.minArmHang,
                    setup[1]?.maxArmHang,
                    dtl1Data.setup_arm_hang
                  )}
                >
                  {dtl1Data.setup_arm_hang}
                </TableCell>
                <TableCell align="center">{setup[1]?.maxArmHang}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default SetupDTLDetailed;
