import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { observer } from "mobx-react-lite";
import Timer from "./timer";
import AppRouter from "./routers/AppRouter";
import AuthStore from "./stores/auth";
import { createContext, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

import {
  AnalysisContext,
  AuthContext,
  CoachingStoreContext,
} from "./stores/contexts";
import AnalysisStore from "./stores/analysis";
import CoachingStore from "./stores/coaching";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDg1x41G7f7RXYGbdabuP1bEPWVf5WABE8",
  authDomain: "steadyheady-aeaec.firebaseapp.com",
  databaseURL: "https://steadyheady-aeaec.firebaseio.com",
  projectId: "steadyheady-aeaec",
  storageBucket: "steadyheady-aeaec.appspot.com",
  messagingSenderId: "94816289206",
  appId: "1:94816289206:web:50aa9c7b3997d6e8ac8117",
  measurementId: "G-V23RQMQM93",
};

firebase.initializeApp(firebaseConfig);

const App = observer(() => {
  return (
    <AuthContext.Provider value={new AuthStore()}>
      <AnalysisContext.Provider value={new AnalysisStore()}>
        <CoachingStoreContext.Provider value={new CoachingStore()}>
          <AppRouter />
        </CoachingStoreContext.Provider>
      </AnalysisContext.Provider>
    </AuthContext.Provider>
  );
});

export default App;
