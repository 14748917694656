import { observer } from "mobx-react-lite";
import * as firebase from "firebase";
import { CSVLink, CSVDownload } from "react-csv";
import { AnalysisContext, AuthContext } from "../stores/contexts";
import React, { useContext, useEffect, useState } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import { useHistory } from "react-router-dom";

function getListLabel(item) {
  let dateFormat = moment(item.time.toDate()).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );
  let label = `${dateFormat} | ${item.presetName}`;
  return label;
}

const AnalysisList = observer(() => {
  const db = firebase.firestore();
  const storage = firebase.storage();

  const [analysisData, setAnalysisData] = useState([]);

  const auth = useContext(AuthContext);
  const analysis = useContext(AnalysisContext);
  let history = useHistory();
  useEffect(() => {
    (async () => {
      let analysisSnap = await db
        .collection("analyzed_videos")
        .where("email", "==", auth.user.email)
        .where("completed", "==", true)
        .orderBy("time", "desc")
        .limit(50)
        .get();

      analysisSnap.forEach(async (doc) => {
        let analysis = doc.data();

        analysis.id = doc.id;
        analysis.video_url = await storage
          .ref()
          .child("analyzed_videos/" + analysis.videoName + ".mp4")
          .getDownloadURL();
        analysis.raw_video_url = await storage
          .ref()
          .child(analysis.original_video_name)
          .getDownloadURL();
        setAnalysisData((prevArray) => [...prevArray, analysis]);
      });
    })();
  }, [auth.user.email, auth.user.uid, db, storage]);

  useEffect(() => {
    console.log("anlysis list ", analysisData);
    const sortedAnalysis = analysisData.sort(
      (a, b) => b.time.toDate() - a.time.toDate()
    );
    setAnalysisData(sortedAnalysis);
  }, [analysisData]);

  return (
    <div>
      <ListSubheader component="div" id="nested-list-subheader">
        Analysis Videos
      </ListSubheader>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        {analysisData.map((req) => (
          <ListItem
            button
            key={req.id}
            onClick={() => {
              analysis.setSelectedAnalysis(req);
              history.push("/analysis");
            }}
          >
            <ListItemText primary={getListLabel(req)} />
          </ListItem>
        ))}
      </List>
    </div>
  );
});

export default AnalysisList;
