import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as firebase from "firebase";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { AuthContext, CoachingStoreContext } from "../stores/contexts";
import { Elements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: 113,
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const ViewStudents = observer((props) => {
  const auth = useContext(AuthContext);
  const coachStore = useContext(CoachingStoreContext);
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();

  useEffect(() => {
    (async () => {
      let coachId = auth.user.uid;

      let students = await coachStore.getCoachStudents(coachId);

      setStudents(students);
      console.log("students in view ", students, coachId);
    })();
  }, []);
  return (
    <div>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <ListSubheader component="div" id="nested-list-subheader">
            Subscribed Students
          </ListSubheader>
          <List>
            {students.map((student, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  setSelectedStudent(student);
                  console.log("selecting student ", student);
                }}
              >
                <ListItemText primary={student.studentEmail} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        style={{ marginTop: 20 }}
      >
        <h1>Student</h1>
      </Box>
    </div>
  );
});

export default ViewStudents;
