import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../stores/contexts";
import * as firebase from "firebase";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TopBar from "../components/TopBar";
import LoginPage from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Coach from "../pages/Coach";
import PrivateRoute from "../components/PrivateRoute";

import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory,
  withRouter,
} from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/auth";
import Analysis from "../pages/Analysis";
import DiagnosticAnalysis from "../pages/DiagnosticAnalysis";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentFailure from "../pages/PaymentFailure";
var firebaseui = require("firebaseui");

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return (
    <div style={{ marginTop: 50 }}>
      <h3>Protected</h3>
    </div>
  );
}

const AppRouter = observer((props) => {
  const [authCheckComplete, setAuthCheckComplete] = useState(false);

  const auth = useContext(AuthContext);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        console.log("USER ", user);
        auth.setUser(user);
        auth.setLoggedIn(true);
        setAuthCheckComplete(true);
        // ...
      } else {
        // User is signed out.
        // ...
        auth.setLoggedIn(false);
        setAuthCheckComplete(true);
      }
    });
  }, [auth]);

  console.log("props ", props);

  if (!authCheckComplete) {
    return <div>Loading</div>;
  } else {
    return (
      <Router>
        <div>
          <TopBar />
          <Switch>
            <Route path="/public">
              <PublicPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>

            <PrivateRoute exact path={"/coach/:id"}>
              {/*<Coach />*/}
              <DiagnosticAnalysis />
            </PrivateRoute>
            <PrivateRoute path={"/analysis"}>
              <Analysis />
            </PrivateRoute>

            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path={"/payment_success"}>
              <PaymentSuccess />
            </PrivateRoute>
            <PrivateRoute path={"/payment_failure"}>
              <PaymentFailure />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    );
  }
});

export default AppRouter;
